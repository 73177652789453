import { useQuery } from "react-query"

const options = {
 headers: {
   'Authorization': 'Bearer oKYub2hdUh5CmlLwRUBPINBfs2B-oTJf',
 },
}


const limit =200

export const useDataQuery = (groupId, firstDayWeek, lastDayWeek)  => useQuery(['data'], async  () => {
  const response = await fetch(`https://student.tiace.uz/rest/v1/data/schedule-list/?&limit=${limit}&_group=${groupId}&lesson_date_from=${firstDayWeek}&lesson_date_to=${lastDayWeek}`, options)
 return await response.json()
},{enabled: !!groupId})

  