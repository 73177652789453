import React from 'react'
import "./footer.scss"

function Footer() {
  return (
    <div>
     <section className='section_footer'>
     <p className='footer_text' >Copyright © 2022 - TAQU | Raqamli ta’lim texnologiyalari markazi</p>
     </section>
    </div>
  )
}

export default Footer