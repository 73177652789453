import React from "react";
import emptyLogo from "../../img/open-box.png";
import { useTranslation } from "react-i18next";

import './empty.scss'


const EmptyTime = () => {
 const { t } = useTranslation();

  return (
    <>
      <div className="parent_img_empty">
        <span>
          <img className="empty_logo" src={emptyLogo} alt="LOGO" />
        </span>
        <span className="text_empty">{t("Empty_title.title")}</span>
      </div>
    </>
  );
};

export default EmptyTime;
