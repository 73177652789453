import React from 'react'
import "./error.scss"

function Error() {
  return (
    <div>
    <div className='img_404_parent'>
    <img src={require('../../img/404_1.jpg')} alt="404" />
    </div>
    </div>
  )
}

export default Error