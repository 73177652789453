import React from "react";
import EmptyTime from "../empty/emptyTime";
import "./timeTable.scss"
import Table from "react-bootstrap/Table";

const GetSaturday = ({ faculties, weeks, t, typeSubject }) => {
  const _faculties = faculties.filter((item) => item.lesson_date === weeks.s);
  if (_faculties.length === 0 || _faculties === undefined) return <EmptyTime/>;

  return   (
    <div className="table-responsive">
      <Table striped responsive>
        <thead>
          <tr>
            <th>#</th>
            <th className="d-sm-table-cell">
              {t("Timetable_trans_titles.Enter")}
            </th>
            <th className=" d-sm-table-cell">{t("Timetable_trans_titles.Exit")}</th>
            <th className=" d-sm-table-cell col_2">{t("Timetable_trans_titles.Subject_Name")}</th>
            <th className=" d-sm-table-cell">
              {t("Timetable_trans_titles.Room_number")}
            </th>
            <th className=" d-sm-table-cell">
              {t("Timetable_trans_titles.Science_type")}
            </th>
            <th className=" d-sm-table-cell">
              {t("Timetable_trans_titles.Teacher")}
            </th>
          </tr>
        </thead>
        <tbody>
          {_faculties.map((item) => (
            <tr key={item.id}>
              <td>{item.lessonPair.name}</td>
              <td className="d-sm-table-cell">{item.lessonPair.start_time}</td>
              <td className=" d-sm-table-cell">{item.lessonPair.end_time}</td>
              <td className=" d-sm-table-cell">{item.subject.name}</td>
              <td className="col_number_room d-sm-table-cell">{item.auditorium.name}</td>
              <td className=" d-sm-table-cell">
                {t(typeSubject[item.trainingType.name])}
              </td>
              <td className=" d-sm-table-cell">{item.employee.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
};
export default GetSaturday;
