import { useState } from "react";
import { useDataQuery } from "../../hooks/fetchData";
import NavbarSite from "../Navbar/Navbar";
import "./facultys.scss"
import Card from 'react-bootstrap/Card';
import facultys from "../../json/faculties.json"
import groups from "../../json/groups_Arch.json"
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import { useTranslation } from 'react-i18next';


const TiaceFaculty = () => {
  // const { data } = useDataQuery();


  // const faculties = data?.data.items.map(item => item.faculty)


  // const resultFaculties = faculties?.reduce((acc, curr) => {
  //   if (!acc.some(item => item.id === curr.id)) {
  //     acc.push(curr)
  //   }

  //   return acc
  // }, [])


  const translations = {
    "Arxitektura": "Translation.Arxitektura",
    "Bino va inshootlar qurilishi": "Translation.Bino inshootlar qurilishi",
    "Muhandislik qurilish infrastrukturasi": "Translation.Muhandislik qurilish infrastrukturasi",
    "Qurilishni boshqarish": "Translation.Qurilishni boshqarish",
    "Magistratura bo‘limi": "Translation.Magistratura bo‘limi",
    // "Sirtqi bo‘lim": "Translation.Sirtqi bo‘lim",
  }

  const { t, i18n,  } = useTranslation();
  // const itemsWithImage = facultys.data.items.map((item, index) => {
  //   return {
  //     ...item,
  //     imgSrc: `${process.env.PUBLIC_URL}/images/${item.id}.png`
  //   }
  // })


  
  return (
    <div>
      <NavbarSite />
      <section className="sec_facultys">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="facultys_text_parent">
                <h1 className="facultys_text">{t("Fakultet.1")}</h1>
              </div>
            </div>
          </div>
          <div className="row">
          {
              facultys.data.items   
                .sort((a, b) => a.id > b.id ? 1 : -1)
                .map((item, i, socials) => (
                  <div key={item.id} className="col-md-4">
                    <Link className="card_title" to={`/facultys/${item.id}`}>
                      <Card className="card_facultys" >
                        <Card.Img src={require(`../../img/${i + 1}.png`)}/>
                        <Card.Body>
                          <Card.Title className="card_title" key="{item.id}">{t(translations[item.name])}</Card.Title>
                        </Card.Body>
                      </Card>
                    </Link>
                  </div>
                ))
            }
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default TiaceFaculty; 