import React, { useState } from "react";
import NavbarSite from "../Navbar/Navbar";
// import { useDataQuery } from "../../hooks/fetchData";
import "../Facultys/facultys.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./courses.scss";
import "./arx-1.scss";
import ArxFirstkurs from "../../json/without_courses/groups_Arch.json";
import BiqFirstKurs from "../../json/without_courses/groups_BIQ.json";
import Master from "../../json/without_courses/groups_MASTER.json";
import Mqi from "../../json/without_courses/groups_MQI.json";
import Qbf from "../../json/without_courses/groups_QBF.json";
import Sirtqi from "../../json/without_courses/groups_SIRTQI.json";
import { useParams, Link } from "react-router-dom";
import Footer from "../footer/footer";
import { useTranslation } from "react-i18next";
import { useStore } from "../../zustand/store";

function CoursesFacultys() {

  const {setData} = useStore()
  const AllData = {
    1: ArxFirstkurs,
    2: BiqFirstKurs,
    5: Master,
    3: Mqi,
    4: Qbf,
    6: Sirtqi,
  };

  const { slug } = useParams();
  const { id } = useParams();


  const { t } = useTranslation();

  return (
    <div>
      <NavbarSite />
      <section className="sec_facultys">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="facultys_text_parent">
                <h1 className="facultys_text">{t("Kurs_title.title")}</h1>
                <Tabs
                  defaultActiveKey="home"
                  id="justify-tab-example"
                  className="mb-3 ul_tabs"
                  justify>
                  <Tab eventKey="home" title={t("Kurs_number.first_title")}>
                    {AllData[slug].data.items
                      .filter((item) => {
                        return item.name.includes(-23);
                      })
                      .sort((a, b) => (a.id > b.id ? 1 : -1))
                      .map((item) => (
                        <ul key={item.name}>
                          <Link
                            className="link_for_groups"
                            to={`/groups/${item.id}`}
                            onClick={() => setData(item.name)}>
                             <li className={`link_groups ${item.id === id ? 'active' : ''}`}>
            {item.name}
          </li>
                          </Link>
                        </ul>
                      ))}
                  </Tab>
                  <Tab eventKey="profile" title={t("Kurs_number.second_title")}>
                    {AllData[slug].data.items
                      .filter((item) => {
                        return item.name.includes(-22);
                      })
                      .sort((a, b) => (a.id > b.id ? 1 : -1))
                      .map((item) => (
                        <ul key={item.name}>
                          <Link
                            className="link_for_groups"
                            to={`/groups/${item.id}`}>
                            <li className="link_groups">{item.name}</li>
                          </Link>
                        </ul>
                      ))}
                  </Tab>
                  {AllData[slug].data.items.some(
                    (item) => item.department.id === 5
                  ) ? null : (
                    <Tab
                      eventKey="longer-tab"
                      title={t("Kurs_number.third_title")}>
                      {AllData[slug].data.items
                        .filter((item) => {
                          return item.name.includes(-21);
                        })
                        .sort((a, b) => (a.id > b.id ? 1 : -1))
                        .map((item) => (
                          <ul key={item.name}>
                            <Link
                              className="link_for_groups"
                              to={`/groups/${item.id}`}>
                              <li className="link_groups">{item.name}</li>
                            </Link>
                          </ul>
                        ))}
                    </Tab>
                  )}

                  {AllData[slug].data.items.some(
                    (item) => item.department.id === 5
                  ) ? null : (
                    <Tab
                      eventKey="course-5"
                      title={t("Kurs_number.four_title")}>
                      {AllData[slug].data.items
                        .filter((item) => {
                          return item.name.includes(-20);
                        })
                        .sort((a, b) => (a.id > b.id ? 1 : -1))
                        .map((item) => (
                          <ul className="ul_end" key={item.name}>
                            <Link
                              className="link_for_groups"
                              to={`/groups/${item.id}`}>
                              <li className="link_groups">{item.name}</li>
                            </Link>
                          </ul>
                        ))}
                    </Tab>
                  )}
                  {AllData[slug].data.items.some(
                    (item) =>
                      item.department.id === 1 || item.department.id === 6
                  ) ? (
                    <Tab eventKey="contact" title={t("Kurs_number.five_title")}>
                      {AllData[slug].data.items
                        .filter((item) => item.name.includes("-19"))
                        .sort((a, b) => (a.id > b.id ? 1 : -1))
                        .map((item) => (
                          <ul className="ul_end" key={item.name}>
                            <Link
                              className="link_for_groups"
                              to={`/groups/${item.id}`}>
                              <li className="link_groups">{item.name}</li>
                            </Link>
                          </ul>
                        ))}
                    </Tab>
                  ) : null}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default CoursesFacultys;
