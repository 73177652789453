import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import './i18next'
import i18n from './i18next';
const queryClient = new QueryClient();



const root = ReactDOM.createRoot(document. getElementById('root'));
root.render(
  <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <Suspense fallback="elyorre.....">
      <QueryClientProvider client={queryClient}>
          <App />
      </QueryClientProvider>
    </Suspense>
  </I18nextProvider>
  </React.StrictMode>
);

