import React, { useContext } from 'react'
import NavbarSite from '../Navbar/Navbar'
import "./homepage.scss"
import logo from "../../img/TAQU_logo.png"
import { Link } from 'react-router-dom'
import Footer from '../footer/footer'


function HomePage() {
  return (
    <>    
    <section className='sec__home_page'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 '>
            <div className='card card_homePage'>
            <div className='logo_img_parent'>
              <img className='logo' src={logo} alt="LOGO" />
            </div> 
            <div className='text__home_page_parent'>
              <p className='homepage_text'>Toshkent arxitektura-qurilish universiteti 
       Dars jadvali</p>
            </div>
            <div className='btn_parent_enter'>
            <Link to='/facultys'><button className=' btn btn-primary btn_enter'>Kirish</button></Link>
            </div>
            </div>
          
            
          </div>
        </div>
      </div>
      
    </section>
    {/* <Footer/> */}
    </>
  )
}

export default HomePage