import React, { useState, useEffect, } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import "./navbar.scss"
import logo from '../../img/TAQU_logo.png'
import { withTranslation ,useTranslation } from 'react-i18next';



function NavbarSite(args) {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  
  
  
  
  
  const { t, i18n } = useTranslation();

  const [selectLang, setSelectLang] = useState("uz")

console.log("selec:", 118n.changeLanguage);

  let classLanguage = document.getElementsByClassName('lang')
  
  
  function onChangeLanguages (e) {
    i18n.changeLanguage(e.target.value)
    setSelectLang(e.target.value)
  }

  
  // useEffect(() => {
  //     for( let index = 0; index < classLanguage.length; index++) {
  //        const element = classLanguage[index]
  //        if (element.value === i18n.language) {
  //          element.setAttribute("selected", 'true')
  //          setSelectLang(element.value)
  //        }
  //     }
  // }, [])


 
  useEffect(() => {
    // Your existing code to handle language selection
    // ...

    // If the language hasn't been set yet, set it to "uz"
    if (!i18n.language) {
      i18n.changeLanguage('uz');
    }

    // Use classList to manipulate classes instead of directly setting attributes
    for (let index = 0; index < classLanguage.length; index++) {
      const element = classLanguage[index];
      if (element.value === i18n.language) {
        element.selected = true;
        setSelectLang(element.value);
      }
    }
  }, [selectLang]);

  return (
    <Navbar className='navbar_own' expand="lg">
      <Container >
        <Link to='/facultys'>
          <Navbar.Brand>
            <img className='logo' src={logo} alt="LOGO" />
          </Navbar.Brand>
        </Link>
        <Link className='navbar_brand_text' to='/facultys'> <h3 className='navbar_brand_text'>{t('logo_name.1')}<br />
          {t('logo_name.2')}</h3></Link>
        <select name="selectedFruit" value={selectLang} defaultValue="uz" className="form-select form_select" onChange={onChangeLanguages}>
          <option  value='uz' className='lang'>Uzbek</option>
          <option  value="ru" className='lang' >Russian</option>
          <option   value="en" className='lang'>English</option>
          {/* <option   value="tr" className='lang'>Türkçe</option> */}
        </select>

      </Container>

    </Navbar>
  );
}

export default  NavbarSite;