import { React, useEffect, useState } from "react";
import { useDataQuery } from './hooks/fetchData'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from "./components/homepage/HomePage";
import TiaceFaculty from "./components/Facultys/facultys";
import CoursesFacultys from "./components/courses/courses";
import facultys from "../src/json/faculties.json"
import TimeTabele from '../src/components/TimeTable/TimeTabel'
import Error from "./components/Error/Error";
import './spinner.scss'
import './i18next'
import ContentLoader from "./components/loader/contentLoader";
import LoadingBar from 'react-top-loading-bar'



function App() {



  // const facultyId = facultys.data.items.map(item=> item.id)
  const { isLoading, isError } = useDataQuery(false);
  const [progress, setProgress] = useState(100)
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (isLoading) {
      setLoading(true)
    }
    if (!isLoading) {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }, [isLoading])

  if (loading) {
    return <div className="Spinner">
      <ContentLoader/>
      <LoadingBar
        color='#2b416b'
        height= {5}
        waitingTime={10000}
        transitionTime={100}
        progress={progress}
        // onLoaderFinished={() => setProgress(0)}
/>
    </div>
  }

  if (isError) {
    return <div>Error occured</div>
  }
  return (
 <div className="app">
    <Router>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="facultys" element={<TiaceFaculty />} />
        <Route path={'facultys/:slug'} element={<CoursesFacultys />} />
        <Route path="groups" >
          <Route index element={<TimeTabele />} />
          <Route path=":groupId" element={<TimeTabele />} />
        </Route>
        <Route path="*" element={<Error />} />
        <Route />
      </Routes>
    </Router>
 </div>
 

  );
}

export default App;
