import React from 'react';
// import Image from "next/image";
import { motion } from "framer-motion"
import loaderPng from '../../img/TAQU_logo.png'

const ContentLoader = () => {
    return (
        <div className={'flex min-h-[75vh] bg-[#F4F8FA] justify-center items-center'}>
            <motion.div  transition={{ repeat: Infinity, repeatDelay: 0.25 }}  animate={{
                scale: [1, 1, 1, 1, 1],
                rotate: [0, 0, 360, 360, 0],
            }}><img width={70} height={65} src={loaderPng} alt={'loader'}/></motion.div>
        </div>
    );
};

export default ContentLoader;