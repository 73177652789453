import i18n from "i18next";
import {  initReactI18next, } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import HTTPApi from "i18next-http-backend"
import Backend from 'i18next-http-backend';

const languages = ['uz', 'ru', 'en'];

i18n
.use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HTTPApi)
  .init({
    
    // lng: "en", // if you're using a language detector, do not define the lng option
    // saveMissing: true,
    supportedLngs: ['uz', 'ru', 'en', 'tr'],
    fallbackLng: 'uz',
    debug: true,
    // whitelist: languages,
    detection: {
        order: ['cookie', 'htmlTag', 'localStorage', 'subdomain', 'path'],
        caches: ['cookie','localStorage'] 
    },
    react: {
      wait: true,
      useSuspense: false,

    },

    
    returnObjects: true,
    backend: {
     loadPath: '/locales/{{lng}}/{{ns}}.json',
     
    },
    

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
  
  export default i18n;