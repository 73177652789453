import { useState, useEffect } from "react";
import NavbarSite from "../Navbar/Navbar";
import { useDataQuery } from "../../hooks/fetchData";
import { useParams, Navigate } from "react-router-dom";
import "./timeTable.scss";
import Footer from "../footer/footer";
import { useTranslation } from "react-i18next";
import ContentLoader from "../loader/contentLoader";
import moment from "moment";
import GetSaturday from "./getDataSaturday";
import GetMonday from "./getDataMondey";
import GetThuesday from "./getDataThuesday";
import GetWednesday from "./getDataWednesday";
import GetThursday from "./getDataThursday";
import GetFriday from "./getDataFriday";
import { useStore } from "../../zustand/store";

function TimeTabele() {
  const typeSubject = {
    Amaliy: "Translation_type_subject.Practice",
    "Ma’ruza": "Translation_type_subject.Lecture",
    Laboratorya: "Translation_type_subject.Laboratory",
    Seminar: "Translation_type_subject.Seminar",
  };
  const {userData} = useStore()

  const [dataFaculties, setDataFaculties] = useState("Bu kun sizga dars yo'q");
  const [weeks, setWeeks] = useState([]);

  const currentDate2 = moment().utc();
  const weekStart = currentDate2.clone().startOf("isoWeek");
  const weekEnd = currentDate2.clone().endOf("isoWeek");
  const lastWeekDay = weekEnd.subtract(1, "days");

  const firstDayWeek = moment(weekStart).unix();
  const lastDayWeek = moment(lastWeekDay).unix();


  const wekDays = {
    m: moment(currentDate2).isoWeekday("Monday").clone().startOf("day").unix(),
    tU: moment(currentDate2)
      .isoWeekday("Tuesday")
      .clone()
      .startOf("day")
      .unix(),
    w: moment(currentDate2)
      .isoWeekday("Wednesday")
      .clone()
      .startOf("day")
      .unix(),
    tH: moment(currentDate2)
      .isoWeekday("Thursday")
      .clone()
      .startOf("day")
      .unix(),
    f: moment(currentDate2).isoWeekday("Friday").clone().startOf("day").unix(),
    s: moment(currentDate2)
      .isoWeekday("Saturday")
      .clone()
      .startOf("day")
      .unix(),
  };



  useEffect(() => {
    setWeeks(wekDays);
  }, []);

  const { t } = useTranslation();
  const { groupId } = useParams();
  const { data, isLoading } = useDataQuery(groupId, firstDayWeek, lastDayWeek);
  const faculties = data?.data?.items.filter((item) => {
    if (
      item.weekStartTime <= firstDayWeek &&
      lastDayWeek >= item.weekStartTime
    ) {
      return item;
    }
  });
  console.log("faculties:",faculties)
  useEffect(() => {
    setDataFaculties(faculties);
  }, []);

  if (!groupId) return <Navigate to="/" />;
  if (isLoading) return <ContentLoader />;



  
  return (
    <div>
      <NavbarSite />
      <section className="sec_facultys">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="facultys_text_parent">
                <h1 className="facultys_text">{t("Timetable_title.title")}</h1>
                <h2 className="group__name">{faculties.length === 0 ? userData :  faculties[0].group.name}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card__two">
                <div className="card-header">{t("week_days.monday")}</div>
                <div className="card-body">
                  <blockquote className="blockquote mb-0 table-responsive-sm table-responsive-md">
                   <GetMonday  faculties={faculties} weeks={weeks} t={t} typeSubject={typeSubject}/>
                  </blockquote>
                </div>
              </div>
              <div className="card card__two">
                <div className="card-header">{t("week_days.tuesday")}</div>
                <div className="card-body">
                  <blockquote className="blockquote mb-0 table-responsive-sm table-responsive-md">
                   <GetThuesday faculties={faculties} weeks={weeks} t={t} typeSubject={typeSubject}/>
                  </blockquote>
                </div>
              </div>
              <div className="card card__two">
                <div className="card-header">{t("week_days.wednesday")}</div>
                <div className="card-body">
                  <blockquote className="blockquote mb-0 table-responsive-sm table-responsive-md">
                  <GetWednesday faculties={faculties} weeks={weeks} t={t} typeSubject={typeSubject} />
                  </blockquote>
                </div>
              </div>
              <div className="card card__two">
                <div className="card-header">{t("week_days.thosday")}</div>
                <div className="card-body">
                  <blockquote className="blockquote mb-0 table-responsive-sm table-responsive-md">
                    <GetThursday faculties={faculties} weeks={weeks} t={t} typeSubject={typeSubject} />
                  </blockquote>
                </div>
              </div>
              <div className="card card__two">
                <div className="card-header">{t("week_days.friday")}</div>
                <div className="card-body">
                  <blockquote className="blockquote mb-0 table-responsive-sm table-responsive-md">
                   <GetFriday faculties={faculties} weeks={weeks} t={t} typeSubject={typeSubject}/>
                  </blockquote>
                </div>
              </div>
              <div className="card card__two">
              <div className="card-header">{t("week_days.saturday")}</div>
                <div className="card-body">
                  <blockquote className="blockquote mb-0 table-responsive-sm table-responsive-md">
                  <GetSaturday faculties={faculties} weeks={weeks} t={t} typeSubject={typeSubject}/>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default TimeTabele;
